//
// New nav stuff
//

.file-navigation-options {
  float: right;
  margin-left: $spacer-1;

  // stylelint-disable-next-line primer/no-override
  .dropdown-menu {
    // stylelint-disable-next-line primer/responsive-widths
    width: 360px;
    padding: $spacer-3;
  }

  // stylelint-disable-next-line primer/no-override
  .dropdown-divider {
    margin: $spacer-3 (-$spacer-3);
  }
}

.file-navigation-option {
  position: relative;
  display: inline-block;
  margin-left: $spacer-1;

  .select-menu {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .select-menu-button {
    .octicon:only-child {
      // stylelint-disable-next-line primer/spacing
      margin-left: 2px;
    }
  }

  // stylelint-disable-next-line primer/utilities
  .zeroclipboard-button {
    padding-right: $spacer-2;
  }

  // stylelint-disable-next-line primer/no-override
  .input-group {
    width: 290px;

    // stylelint-disable-next-line primer/no-override
    .form-control {
      width: calc(100% + 2px);
      // Height/Min-height Temporary fix
      // FF doens't like the min-height on .input-sm for some reason.
      // Should be re-evaluated and moved to Primer in the future.
      height: 28px;
      min-height: 0;
      // stylelint-disable-next-line primer/spacing
      margin-right: -1px;
      // stylelint-disable-next-line primer/spacing
      margin-left: -1px;
      border-radius: 0;
    }

    .select-menu-button {
      position: relative;
      z-index: 2;
    }
  }
}

.reponav-item .hx_reponav_item_counter {
  min-width: 0;
  line-height: $lh-condensed;
}
